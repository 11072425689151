import { get } from "lodash";
import * as nodeServer from "../Clients/NodeServer";

const initialState = {
	dashboardMenus: [],
	error: null,
	isFetching: false,
	isFetched: false,
};

let _dashboards = { ...initialState };

const dashboardSubscribers = new Set();

const store = {
	//TODO: replace with get snapshot
	getDashboards: () => {
		return _dashboards;
	},
	setDashboards: (dashboards) => {
		_dashboards = dashboards;
		for (const cb of dashboardSubscribers) {
			cb();
		}
	},
	getDashboardMenusByNamespace(namespace) {
		return (
			_dashboards.dashboardMenus.find((d) => d._id === namespace)?.dashboards ??
			[]
		);
	},
	subscribeToDashboards(callback) {
		dashboardSubscribers.add(callback);
		return () => dashboardSubscribers.delete(callback);
	},
	getFilters(namespace, dashboardSlug) {
		return get(
			JSON.parse(localStorage.getItem("dashboardFilters") ?? "{}"),
			[namespace, dashboardSlug],
			{},
		);
	},
	getDefaultDashboard(namespace) {
		const dashboards =
			_dashboards.dashboardMenus
				.find((d) => d._id === namespace)
				?.dashboards?.filter((d) => !d.disabled)
				.flatMap((d) => d.items) ?? [];

		const defaultDashboard = dashboards.find((d) => d.default && !d.disabled);
		const fallbackDashboard = dashboards.at(0);

		return defaultDashboard ?? fallbackDashboard;
	},
};

const actions = {
	async fetchDashboards(token, assumeUserId) {
		if (_dashboards.isFetching) {
			return;
		}

		store.setDashboards({
			..._dashboards,
			isFetching: true,
		});
		try {
			const resp = await nodeServer.getDashboards(token, assumeUserId);
			store.setDashboards({
				..._dashboards,
				dashboardMenus: resp.data,
				isFetching: false,
				isFetched: true,
			});
		} catch (e) {
			store.setDashboards({
				..._dashboards,
				error: e,
				isFetching: false,
				isFetched: true,
			});
		}
	},
	setFilter(namespace, dashboardSlug, newFilters) {
		const prevAllFilters =
			JSON.parse(localStorage.getItem("dashboardFilters")) ?? {};
		const prevDashboardFilters = get(
			prevAllFilters,
			[namespace, dashboardSlug],
			{},
		);
		const prevNamespaceFilters = prevAllFilters?.namespace ?? {};

		if (JSON.stringify(newFilters) === JSON.stringify(prevDashboardFilters)) {
			return;
		}

		//Shallow clone object and add new selection to work with useSyncExternalStore
		const nextAllFilters = {
			...prevAllFilters,
			[namespace]: {
				...prevNamespaceFilters,
				[dashboardSlug]: newFilters,
			},
		};

		const nextAllFiltersString = JSON.stringify(nextAllFilters);

		localStorage.setItem("dashboardFilters", nextAllFiltersString);

		store.setDashboards({
			..._dashboards,
			dashboardFilters: nextAllFilters,
		});
	},
	resetState() {
		store.setDashboards({ ...initialState });
	},
};

export { store, actions };
